import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';

const Volunteer = ({ data }) => {
  const { volunteer } = data;

  const applicationsSection = ({
    applicationsHeading,
    applicationsText,
    applicationsLink,
    applicationsLinkText,
  }) => (
    <div className="volunteer-applications container row">
      <div className="col-sm-7 offset-sm-2 col-xs-6 col">
        {applicationsHeading && <h2 className="gothic--large">{applicationsHeading}</h2>}
        {applicationsText && <div className="applications-text content" dangerouslySetInnerHTML={{ __html: applicationsText }} />}
        {applicationsLink && <a className="applications-link link sans--regular" href={applicationsLink}>{applicationsLinkText ?? 'Apply here'}</a>}
      </div>
    </div>
  );

  return (
    <Layout bgcolor={volunteer.backgroundColor} wrapperClass="volunteer" accentColor={volunteer.accentColor}>
      <HelmetDatoCms seo={volunteer.seoMetaTags} />
      <div className="volunteer-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{volunteer.title}</h1>
          {volunteer.featuredImage && (
            <Img fluid={{ ...volunteer.featuredImage.fluid }} alt={volunteer.featuredImage.alt} className="volunteer-image" />
          )}
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: volunteer.description,
            }}
          />
        </div>
      </div>
      {applicationsSection(volunteer)}
      <div className="divider-bottom container row">
        <div className="col-sm-5 offset-sm-3 col-xs-4 offset-xs-1 col">
          <DividerBlock accentColor={volunteer.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

Volunteer.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Volunteer;

export const query = graphql`
  query VolunteerQuery {
    volunteer: datoCmsVolunteer {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      title
      featuredImage {
        alt
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      description
      applicationsHeading
      applicationsLink
      applicationsLinkText
      applicationsText
    }
  }
`;
